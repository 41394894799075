/* src/components/About/About.css */
.about {
  background-color: #f4f4f4; /* Light background to contrast with header */
  padding: 3rem 1rem; /* Padding for spacing */
  color: #333; /* Darker text color for readability */
  text-align: center;
}

.about-content {
  max-width: 800px; /* Limit the width for better readability */
  margin: 0 auto; /* Center the content */
}

.about h1 {
  font-size: 2.5rem; /* Large title for emphasis */
  color: #333; /* Match text color */
  margin-bottom: 1.5rem;
}

.about p {
  font-size: 1.2rem; /* Comfortable font size for body text */
  line-height: 1.8; /* Increase line spacing for readability */
  margin-bottom: 1.5rem; /* Space between paragraphs */
}

.about a {
  display: inline-block;
  background-color: #333; /* Match the header background */
  color: white; /* White text to contrast with background */
  padding: 0.75rem 1.5rem; /* Button padding */
  font-size: 1.2rem;
  text-decoration: none;
  border-radius: 5px; /* Slight rounding for the button edges */
  transition: background-color 0.3s ease; /* Smooth hover transition */
}

.about a:hover {
  background-color: #555; /* Lighter shade on hover */
}

