/* HomeDesktop.css */

/* Hero Section */
.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('../NYC_eastriver.jpg');
    background-size: cover;
    background-position: center;
    color: white;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
    position: relative;
    overflow: hidden;
    z-index: 500;
  }
  
  .hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .hero-content {
    position: relative;
    z-index: 1;
    max-width: 700px;
    text-align: center;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .hero-content p {
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }
  
  /* Animation */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Typography */
  h1 {
    font-size: 4rem;
    margin-bottom: 20px;
    background: linear-gradient(90deg, #ff7f50, #ff6347);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-shadow: none;
    font-weight: bold;
    letter-spacing: 2px;
  }
  
  p {
    font-size: 1.6rem;
    margin-top: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    opacity: 0.8;
  }
  
  /* CTA Button */
  .cta-button {
    background-color: #ff7f50;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 30px;
    text-decoration: none;
    font-size: 1.3rem;
    transition: background-color 0.3s, transform 0.2s;
    margin-top: 40px;
    display: inline-block;
    z-index: 1;
  }
  
  .cta-button:hover {
    background-color: #ff6347;
    transform: translateY(-5px);
  }
  
  /* About Me Section */
  .about-me-section {
    padding: 60px 20px;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .about-me-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 90%;
  }
  
  .about-left,
  .about-right {
    flex: 1;
    margin: 10px;
  }
  
  .about-left p,
  .about-right p {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 1.5rem;
  }

  /* Full-width Projects Section */
.projects-section {
    width: 100%;
    padding: 60px 0px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* Projects Container */
.projects-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 90%; /* 90% width of the page */
  margin: 0 auto; /* Center the container */
  align-items: flex-start; /* Align the featured and minor projects at the top */
  align-items: stretch; 
}
  
  /* Featured project occupies full height */
  .featured-project {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    /* max-width: 90%; */
    width: 65%; /* Adjust as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: transform 0.3s;
  }
  
  /* Minor projects container should also occupy full height */
  .minor-projects {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%; /* Make it occupy full container height */
  }
  
  .minor-project {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex-grow: 1; /* Make each minor project take equal space */
    transition: transform 0.3s, background-color 0.3s;
  }
  
  .minor-project {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    transition: transform 0.3s, background-color 0.3s;
  }
  
  .minor-project:hover {
    transform: translateY(-5px);
    background-color: #f0f0f0;
  }
  
  .featured-project h3 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #333;
    align-self: flex-start;
  }
  
  .featured-project p {
    font-size: 1.2rem;
    color: #666;
    line-height: 1.6;
    flex-grow: 1;
  }
  
  .minor-project h4 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .minor-project p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
  
  @media (max-width: 900px) {
    .projects-container {
      flex-direction: column; /* Stack items vertically */
      padding: 0 5%; /* Adjust padding for smaller screens */
    }
  
    .featured-project {
      width: 95%; /* Full width in stacked layout */
      margin-bottom: 20px; /* Space between stacked elements */
    }
  
    .minor-projects {
      width: 100%; /* Full width in stacked layout */
    }}

  /* Experience Section */
  .experience-section {
    padding: 60px 0px;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .experience-section h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 40px;
    text-align: center;
    color: #333;
  }
  
  .experience-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    width: 90%;
    margin: 0 auto;
    align-items: flex-start;
  }
  
  .featured-experience {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 45%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: transform 0.3s;
  }
  
  .featured-experience h3 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .featured-experience p {
    font-size: 1.2rem;
    color: #666;
    line-height: 1.6;
    flex-grow: 1;
  }
  
  .featured-experience:hover {
    transform: translateY(-5px);
    background-color: #f9f9f9;
  }
  
  /* Animations */
  [data-aos] {
    opacity: 0;
    transition-property: opacity, transform;
  }
  
  [data-aos].aos-animate {
    opacity: 1;
  }
  
  [data-aos='fade-right'] {
    transform: translateX(-50px);
  }
  
  [data-aos='fade-left'] {
    transform: translateX(50px);
  }
  
  [data-aos='zoom-in-up'] {
    transform: scale(0.9) translateY(50px);
  }
  
  [data-aos].aos-animate[data-aos='fade-right'],
  [data-aos].aos-animate[data-aos='fade-left'],
  [data-aos].aos-animate[data-aos='zoom-in-up'] {
    transform: none;
  }
  