/* General header styles */
.header {
  background-color: #333;
  padding: 1rem 4%;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure the header stays on top */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow: hidden; /* make this shwo on screens smaller than 768 */
}
@media (max-width: 768px) {
  .header {
    overflow: visible;
  }
}
.header-left {
  flex: 1;
}

.header-center {
  flex: 2;
  display: flex;
  justify-content: center;
}

.header-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2rem;
}

.header a {
  color: white;
  text-decoration: none;
  font-size: clamp(1rem, 2vw, 1.5rem);
  margin: 0 1vw;
}

.header a:hover {
  color: #ff7f50;
  text-decoration: underline;
}

/* Hide hamburger by default */
.hamburger {
  display: none;
}

/* Remove potential white space on the right */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.small-link {
  font-size: 1rem;
}

.settings-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  margin-left: 1rem;
  cursor: pointer;
}

.settings-button:hover {
  color: #ff7f50;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .header-left {
    flex: 1;
  }

  .header-center {
    display: none; /* Hide center links */
  }

  .header-right {
    justify-content: flex-end;
    width: 100%;
  }

  .hamburger {
    display: block;
    background: none;
    border: none;
    font-size: 2rem;
    color: white;
    cursor: pointer;
  }

  /* Initially hide the dropdown */
  .dropdown {
    display: flex;
    flex-direction: column;
    background-color: #333;
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    z-index: 9999; /* Make sure it's on top of all content */
  }

  /* Show dropdown when the menu is open */
  .header.open .dropdown {
    display: flex;
  }

  .dropdown a {
    padding: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;
  }

  .dropdown a:hover {
    background-color: #444;
  }
}

/* Larger screens: Make sure hamburger is hidden */
@media (min-width: 769px) {
  .hamburger {
    display: none;
  }

  .header-center {
    display: flex; /* Restore the center links on larger screens */
  }

  .dropdown {
    display: none; /* Ensure dropdown doesn't appear on large screens */
  }
}
