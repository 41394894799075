/* Footer Styles */
.footer {
  background-color: #333;
  color: #bfbfbf;
  padding: 2rem 4%;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
}

/* Center all footer content */
.footer-content {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  max-width: 1200px;
  margin: 0 auto;
}

/* Footer paragraph text */
.footer p {
  font-size: clamp(1rem, 2vw, 1.2rem); /* Responsive font size */
  line-height: 1.6;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  color: #bfbfbf;
}

/* Center social links */
.social-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: clamp(10px, 4vw, 30px); /* Responsive gap */
}

.social-links li {
  display: inline-block;
}

/* Contact link styling */
.contact-link {
  color: #bfbfbf;
  font-size: clamp(1rem, 2vw, 1.5rem); /* Responsive link size */
  transition: color 0.3s;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
}

.contact-link:hover {
  color: #ff7f50; /* Hover effect */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .footer-content {
    padding: 1rem 10px; /* Adjust padding for smaller screens */
  }

  .footer p {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  .social-links {
    gap: 15px; /* Smaller gap for mobile */
  }
}