/* Typography */

/* Button Styling */
.cta-button {
  background-color: #ff7f50;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 30px;
  text-decoration: none;
  font-size: 1.3rem;
  transition: background-color 0.3s, transform 0.2s;
}

.cta-button:hover {
  background-color: #ff6347;
  transform: translateY(-5px);
}

/* Animation Effects */
@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(30px); }
  100% { opacity: 1; transform: translateY(0); }
}

[data-aos] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos].aos-animate {
  opacity: 1;
}

[data-aos="fade-right"] { transform: translateX(-50px); }
[data-aos="fade-left"] { transform: translateX(50px); }
[data-aos="zoom-in-up"] { transform: scale(0.9) translateY(50px); }

[data-aos].aos-animate[data-aos="fade-right"],
[data-aos].aos-animate[data-aos="fade-left"],
[data-aos].aos-animate[data-aos="zoom-in-up"] {
  transform: none;
}

/* Responsive Typography */
@media (max-width: 768px) {
  h1 { font-size: 2.5rem; }
  h2 { font-size: 2rem; }
  p { font-size: 1.2rem; }
}

/* Utility Classes */
.container {
  width: 90%;
  margin: 0 auto;
}

.center-text {
  text-align: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
