.projects {
    padding: 2rem;
    background-color: #fff;
  }
  
  .projects h2 {
    font-size: 2rem;
    text-align: center;
  }
  
  .project-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .project {
    background-color: #f0f0f0;
    margin: 1rem;
    padding: 1rem;
    border-radius: 8px;
    width: 30%;
    text-align: center;
  }
  
  .project a {
    display: inline-block;
    margin-top: 1rem;
    text-decoration: none;
    color: #007bff;
  }
  
  .project a:hover {
    text-decoration: underline;
  }
  