.contact {
  text-align: center;
  padding: 50px 20px;
  background-image: url('NYC_worldtrade.JPG');
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center -150px; /* Move the background image up by 50px */
  background-repeat: no-repeat; /* Prevents the background from repeating */
  background-attachment: fixed; /* Fixes the background during scrolling */
  color: #333;
  position: relative;
  overflow: hidden;
  min-height: 1000px;
}


.text-banner {
  /* background: rgba(0, 0, 0, 0.7); Add some transparency to the background for better text readability */
  padding: 50px;
  color: #333;
}

.text-card {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 40px;
  background-color: rgba(240, 240, 240, 0.9); /* Slight transparency for blending with the background */
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact h2 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.contact p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 40px;
  background-color: rgba(240, 240, 240, 0.9); /* Slight transparency for blending with the background */
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group input,
.form-group textarea {
  width: calc(100% - 20px);
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-btn {
  width: 100%;
  padding: 10px;
  background-color: #ff7f50;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #e67350;
}
.thank-you-card {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 40px;
  background-color: rgba(240, 240, 240, 0.9); /* Slight transparency for blending with the background */
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.thank-you-message {
  font-size: 1.5rem;
  color: #333;
  margin-top: 20px;
  font-weight: bold;
}

.success-icon {
  margin-right: 10px;
  color: #28a745; /* Green color for the icon */
  font-size: 1.5rem;
}
.error-card {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 40px;
  background-color: rgba(240, 240, 240, 0.9); /* Slight transparency for blending with the background */
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.error-message {
  color: red;
  font-weight: bold;
  margin-top: 20px;
}
.contact-link {
  color: #bfbfbf;
  font-size: clamp(1rem, 2vw, 1.5rem); /* Responsive link size */
  transition: color 0.3s;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
}