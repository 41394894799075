/* src/components/Home/mobile/HomeMobile.css */

/* General Styles */
body {
    margin: 0;
    font-family: 'Arial', sans-serif;
  }
  
  section {
    width: 100%;
  }
  
  /* Hero Section */
  .hero-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background-image: url('NYC_EmpireState.JPG'); /* Use a mobile-optimized image */
    background-size: cover;
    background-position: center;
    color: white;
    position: relative;
    overflow: hidden;
  }
  
  .hero-mobile::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .hero-content-mobile {
    position: relative;
    z-index: 1;
    max-width: 90%;
    text-align: center;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .hero-content-mobile h1 {
    font-size: 3rem;
    margin-bottom: 15px;
    background: linear-gradient(90deg, #ff7f50, #ff6347);
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .hero-content-mobile p {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .cta-button-mobile {
    background-color: #ff6347;
    color: white;
    padding: 10px 25px;
    border: none;
    border-radius: 30px;
    text-decoration: none;
    font-size: 1.2rem;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .cta-button-mobile:hover {
    background-color: #ff7f50;
    transform: translateY(-3px);
  }
  
  /* About Me Section */
  .about-me-section-mobile {
    padding: 40px 20px;
    max-width: 90%;
    background-color: #ffffff;
  }
  
  .about-me-section-mobile h2 {
    font-size: 2rem;
    color: #ff7f50;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .about-me-content-mobile p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: justify;
    overflow: wrap;
  }
  
  /* Projects Section */
  .projects-section-mobile {
    padding: 40px 20px;
    background-color: #f9f9f9;
  }
  
  .projects-section-mobile h2 {
    font-size: 2rem;
    color: #ff7f50;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .project-item-mobile {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-left: 5px solid #ff7f50;
    border-radius: 5px;
  }
  
  .project-item-mobile h3 {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  
  .project-item-mobile p {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  /* Experience Section */
  .experience-section-mobile {
    padding: 40px 20px;
    background-color: #ffffff;
  }
  
  .experience-section-mobile h2 {
    font-size: 2rem;
    color: #ff7f50;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .experience-item-mobile {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-left: 5px solid #ff6347;
    border-radius: 5px;
  }
  
  .experience-item-mobile h3 {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  
  .experience-item-mobile p {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  /* Animations */
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(30px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  
  [data-aos] {
    opacity: 0;
    transition-property: opacity, transform;
  }
  
  [data-aos].aos-animate {
    opacity: 1;
  }
  
  [data-aos="fade-up"] {
    transform: translateY(20px);
  }
  
  [data-aos].aos-animate[data-aos="fade-up"] {
    transform: translateY(0);
  }
  